<template>
  <v-container fill-height>
    <rpDekstop
      v-if="
        this.$vuetify.breakpoint.name == 'md' ||
        this.$vuetify.breakpoint.name == 'lg' ||
        this.$vuetify.breakpoint.name == 'xl'
      "
    />

    <rpMobile v-else />
  </v-container>
</template>

<script>
import rpDekstop from "@/components/resetpass/rpDekstop";
import rpMobile from "@/components/resetpass/rpMobile";

export default {
  components: {
    rpDekstop,
    rpMobile,
  },

  data: () => ({}),
};
</script>