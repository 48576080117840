<template>
  <v-row justify="center" align="center">
    <v-col cols="12">
      <v-card>
        <v-row>
          <v-col cols="6" class="pa-0">
            <v-carousel cycle height="650" hide-delimiters class="mb-n3">
              <v-carousel-item>
                <v-sheet color="purple lighten-5" height="100%">
                  <v-row class="fill-height" align="center" justify="center">
                    <div>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center"
                      >
                        <v-img
                          :src="require('@/assets/login/' + appL)"
                          max-width="250"
                        ></v-img>
                      </v-row>
                      <div class="text-center mt-5">
                        <h2>{{ appN }}</h2>
                        <h3 class="font-weight-light">{{ appLK }}</h3>
                      </div>
                    </div>
                  </v-row>
                </v-sheet>
              </v-carousel-item>

              <v-carousel-item>
                <v-sheet color="grey lighten-2" height="100%">
                  <v-row class="fill-height" align="center" justify="center">
                    <div>
                      <div class="mb-10">
                        <v-row>
                          <v-col cols="6">
                            <v-img
                              src="@/assets/login/bkn-logo.png"
                              class="mx-auto"
                            ></v-img>
                          </v-col>

                          <v-col cols="6">
                            <v-img
                              src="@/assets/login/logo-satu-asn.png"
                              class="mx-auto"
                              max-width="110"
                            ></v-img>
                          </v-col>
                        </v-row>
                      </div>

                      <div class="text-center mb-10">
                        <h2>TERINTEGRASI DENGAN SISTEM BKN</h2>
                        <h3 class="font-weight-light">
                          MENUJU SATU DATA ASN DENGAN
                        </h3>
                      </div>

                      <div>
                        <v-row align="center" justify="center">
                          <div class="text-center">
                            <v-img
                              src="@/assets/login/logo-sso.png"
                              max-width="60"
                              class="ml-2"
                            ></v-img>
                            <h3>SSO BKN</h3>
                          </div>

                          <v-img
                            src="@/assets/login/logo-mysapk.png"
                            class="mx-10"
                            max-width="100"
                          ></v-img>

                          <div class="text-center">
                            <v-img
                              src="@/assets/login/logo-siasn.png"
                              max-width="60"
                            ></v-img>
                            <h3>SIASN</h3>
                          </div>
                        </v-row>
                      </div>
                    </div>
                  </v-row>
                </v-sheet>
              </v-carousel-item>

              <v-carousel-item>
                <v-sheet color="light-blue lighten-4" height="100%">
                  <v-row class="fill-height" align="center" justify="center">
                    <div>
                      <div>
                        <div class="text-center mb-5">
                          <h2>Integrasi Tanda Tangan Elektronik (TTE)</h2>
                          <span class="font-weight-light">
                            Balai Sertifikasi Elektronik (BSrE) Badan Siber dan
                            Sandi Negara (BSSN)
                          </span>
                        </div>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center"
                        >
                          <v-img
                            src="@/assets/login/full-bsre-logo.png"
                            class="mx-4"
                            max-width="250"
                          ></v-img>

                          <v-img
                            src="@/assets/login/logo-bsn.png"
                            class="mx-4"
                            max-width="90"
                          ></v-img>
                        </v-row>
                      </div>
                    </div>
                  </v-row>
                </v-sheet>
              </v-carousel-item>
            </v-carousel>
          </v-col>

          <v-col cols="6" class="my-auto" v-if="confirmpass">
            <div class="pa-6">
              <div class="mb-6">
                <v-row dense>
                  <v-img
                    :src="require('@/assets/login/' + logo)"
                    max-width="75"
                  ></v-img>
                  <div class="ml-3">
                    <h2 class="font-weight-light">KONFIRMASI RESET PASSWORD</h2>
                    <h3>{{ title.toUpperCase() }}</h3>
                  </div>
                </v-row>
              </div>

              <div class="mb-6" v-if="alertG">
                <v-alert v-model="alertG" type="error" dense dismissible>
                  {{ alertM }}
                </v-alert>
              </div>

              <div class="mb-6">
                <v-alert dense type="info" text>
                  Silahkan cek kotak masuk <strong>email</strong> Anda. Token
                  reset password telah <strong>dikirimkan</strong> ke email yang
                  terhubung dengan akun Anda
                </v-alert>
              </div>

              <form>
                <v-text-field
                  v-model="password"
                  label="Password"
                  placeholder="Masukan Password Baru"
                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show ? 'text' : 'password'"
                  @click:append="show = !show"
                  outlined
                ></v-text-field>
                <v-text-field
                  v-model="token"
                  label="Token"
                  outlined
                ></v-text-field>
                <v-row>
                  <v-col cols="12" md="12">
                    <v-btn
                      class="mb-3"
                      color="primary"
                      block
                      @click="resetpass()"
                    >
                      <span class="font-weight-black"> RESET PASSWORD </span>
                    </v-btn>
                  </v-col>
                </v-row>
              </form>
            </div>
          </v-col>

          <v-col cols="6" class="my-auto" v-else>
            <div class="pa-6">
              <div class="mb-6">
                <v-row dense>
                  <v-img
                    :src="require('@/assets/login/' + logo)"
                    max-width="75"
                  ></v-img>
                  <div class="ml-3">
                    <h2 class="font-weight-light">LUPA PASSWORD</h2>
                    <h3>{{ title.toUpperCase() }}</h3>
                  </div>
                </v-row>
              </div>

              <div class="mb-6" v-if="alertG">
                <v-alert v-model="alertG" type="error" dense dismissible>
                  {{ alertM }}
                </v-alert>
              </div>

              <div class="mb-6">
                <v-alert dense type="info" text>
                  Masukkan <strong>NIP</strong> dan <strong>Email</strong> Anda
                  sebelum memulai untuk melakukan reset password
                </v-alert>
              </div>

              <form>
                <v-text-field
                  v-model="username"
                  label="Nomor Induk Pegawai"
                  outlined
                ></v-text-field>
                <v-text-field
                  v-model="email"
                  label="Email"
                  outlined
                ></v-text-field>
                <v-row>
                  <v-col cols="12" md="12">
                    <v-btn
                      class="mb-3"
                      color="primary"
                      block
                      @click="lanjutkan()"
                      :loading="lbtn"
                    >
                      <span class="font-weight-black"> LANJUTKAN </span>
                    </v-btn>
                    <v-btn color="primary" text block @click="login()">
                      <span class="font-weight-black"> LOGIN </span>
                    </v-btn>
                  </v-col>
                </v-row>
              </form>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>
    
    <script>
export default {
  data: () => ({
    show: false,
    alertG: false,
    lbtn: false,

    confirmpass: false,

    title: process.env.VUE_APP_OPDNAME,
    logo: process.env.VUE_APP_OPDLOGO,

    appN: process.env.VUE_APP_FULLNAME,
    appLK: process.env.VUE_APP_APPLOKASI,
    appT: process.env.VUE_APP_APPNAME,
    appL: process.env.VUE_APP_APPLOGO,
    appFL: process.env.VUE_APP_FULLLOGO,

    alertM: "",

    username: "",
    password: "",
    email: "",
    token: "",

    data: "",
  }),

  methods: {
    lanjutkan() {
      this.lbtn = true;

      const data = {
        username: this.username,
        email: this.email,
      };

      const url = process.env.VUE_APP_AUTH + "forget-password";

      this.http
        .post(url, data)
        .then((res) => {
          this.lbtn = false;
          if (res.data.success) {
            this.alertG = false;
            this.confirmpass = true;
            this.data = res.data.mapData.data;
          } else {
            this.alertG = true;
            this.confirmpass = false;
            this.alertM = res.data.message;
          }
        })
        .catch((err) => {
          console.log(err);
          this.lbtn = false;
          this.alertG = true;
          this.confirmpass = false;
          this.alertM = err.response.data.message;
        });
    },

    resetpass() {
      this.lbtn = true;

      const data = {
        username: this.username,
        passwordBaru: this.password,
        token: this.token,
      };

      const url = process.env.VUE_APP_AUTH + "set-password";

      this.http
        .post(url, data)
        .then((res) => {
          this.lbtn = false;
          if (res.data.success) {
            this.alertG = false;
            this.$router
              .push("/login?client_id=" + this.$route.query.client_id)
              .catch(() => {});
          } else {
            this.alertG = true;
            this.alertM = res.data.message;
          }
        })
        .catch((err) => {
          console.log(err);
          this.lbtn = false;
          this.alertG = true;
          this.alertM = err.response.data.message;
        });
    },

    login() {
      this.$router
        .push("/login?client_id=" + this.$route.query.client_id)
        .catch(() => {});
    },
  },
};
</script>